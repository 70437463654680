<template>
  <div id="static">
    <div class="title">Rules</div>
    <div class="content">
      <p>
        <strong>1. Administration</strong><br />a. Forbidden to discuss actions of the
        administration, as well as those representing them.<br />b. Forbidden to insult the
        administration, as well as those representing them.<br />c. Not claim to be a representative
        of administration. It is also forbidden to extort, to demand, to ask anything of value being
        represented by someone who Authority.<br />* The only people representing the administration
        in any way, it's a user called out on page administration.<br /><br />&nbsp;
      </p>
      <p>
        <strong>2 . Members of the portal</strong><br />a. Forbidden to insult other users of the
        portal . Rule of the Forum of the portal, as well as the server.<br />b. It is forbidden to
        threaten violence in real life, provoke similar topics , discuss them .<br />c. Prohibited
        from using profane, obscene ( Matney ) , insulting , provocative frazyslova .<br />d. It is
        forbidden to insult , provoke, threaten to close any of those users of the portal.<br />e.
        Forbidden to solicit , demand , select, to deceive any of the users of the portal in order
        to obtain anything of value .<br />f. It is forbidden to offer , to exchange the purchase,
        sale of any property. Rule applies to all types of exchange, sales, purchases, except if
        both values belong to our portal.<br />g. Prohibited unauthorized access to the accounts of
        any user of the portal.<br />h. Prohibited publication of the messages containing the
        translated text. Only allowed publication of the messages and Angliykskogo Russian
        languages.<br />i. Prohibited frequent publication of the messages do not carry meaning.<br />j.
        The administrator can not be held responsible for incorrect registration and of the
        character . Recommend that you change your password 1 time per month and properly register
        !<br />* Veiling words also fall under the penalty for sections a, b, c, d , paragraph 2
        .<br />** If the breach occurs through heroic chat , the punishment is increased by 6
        times.<br />*** In serious cases, if a violation occurs through the heroic chat may remove
        the status of hero.<br />**** Also under paragraph f. get messages about finding people from
        other servers.<br />For ***** points a, b, c, d fall under violation of messages only in the
        main , trade , Schouten and Hero chats.<br /><br />&nbsp;
      </p>
      <p>
        <strong>3. The software side member</strong><br />a. Prohibited the use of programs
        interfering with the process of the user &lt;&gt; server.<br />b. It is forbidden to use the
        client portion of modifying the software to work with the portal servers.
      </p>
      <p>
        <strong>4. Advertise on our portal</strong><br />a. It is forbidden to advertise any portal,
        if their content is not directly related to our portal.<br />b. It is forbidden to advertise
        any direct links if of the instructions on the site is directly related to our portal.<br />*
        Pravilo of paragraph 4 shall relate to the game server, and the Forum portal.
      </p>
      <p>
        <br /><strong>5. The use of names, titles</strong><br />a. It is forbidden to create
        characters, accounts, clans, set titles similar to nicknames, clans, titles administration
        of the portal.<br />b. It is forbidden to create characters, accounts, clans, set titles
        contain obscene frazyslova and offending other users of the portal.
      </p>
      <p>* Terms of paragraph 5 shall apply only to the game server portal.<br /><br /></p>
      <p>
        <strong>6. Using portal Server</strong><br />a. Prohibited the use of exploits holes
        uncovered bugs server. In the case of finding any, immediately report it to the
        Administration of the portal.<br />b. Forbidden to deliberately exceeding the limit of sent
        requests to the server package. (Flood, spam, dos, ddos)<br />s. Prohibited the intentional
        blocking of access to the NPC.
      </p>
      <p>d. Wyverns are prohibited on the territory of sieges during them.</p>
      <p>
        <br /><strong>7. Olympiad and Heroes Server</strong><br />a. It is forbidden to violate the
        logical course of action of the Olympics.
      </p>
      <p>
        Penalties for violation of these rules Section 1<br />a. Lock chatavozmozhnosti write
        messages for up to 1000 minutes.<br />b. Blocking a user account for a period of 5000
        minutes to infinity.<br />c. Blocking a user account for a period of 10,000 minutes to
        infinity<br /><br />
      </p>
      <p>
        <strong>section 2</strong><br />a. Lock chatavozmozhnosti write messages for up to 3000
        minutes .<br />b. User Account Locked in perpetuity.<br />c. Lock chatavozmozhnosti write
        messages for up to 5000 minutes .<br />d. User Account Locked in perpetuity.<br />e. User
        Account Locked in perpetuity.<br />f. Lock chatavozmozhnosti write messages of up to 10,000
        minutes for the first time , with repetition lock the user account for unlimited SRO.<br />g.
        User Account Locked in perpetuity.<br />h. User Account Locked in perpetuity.<br />i. Lock
        chatavozmozhnosti write messages for up to 3000 minutes .<br /><strong>section 3</strong
        ><br />a. User Account Locked in perpetuity.<br />b. User Account Locked in perpetuity.<br /><strong
          >section 4</strong
        ><br />a. User Account Locked in perpetuity.<br />b. Lock chatavozmozhnosti write messages
        of up to 10,000 minutes.<br /><strong>section 5</strong><br />a. Deleting a character
        account , clan title.<br />b. Deleting a character account , clan title.<br /><strong
          >section 6</strong
        ><br />a. Lock all user accounts in perpetuity.<br />b. Lock all user accounts in
        perpetuity.<br />c. Prison for up to 7 days.<br />d. Prison for up to 7 days.<br /><strong
          >section 7</strong
        ><br />a. Prison for up to 7 days.
      </p>
      <p><br /><br /><strong style="color: #ff0000">Attention!</strong></p>
      <p>
        <strong
          >Administration of the server reserves the right to ban a player's account, or an entire
          subnet without any reason, if the user is in any way interfere with the further
          development of the portal</strong
        ><br /><strong
          >These rules may be amended or supplemented at any time, without notice.</strong
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rules',
};
</script>

<style scoped>
.title {
  text-align: center;
}

p {
  font-size: 1.2em;
}
</style>
